<template>
    <div class="main">
        <div class="search">
            <div style="color: #fff9ff;font-size: 16px;font-weight: bold;width: 50%">
                {{title}}
            </div>
            <div style="width: 50%;text-align: right">
                <el-button size="mini" type="primary" @click="editBoard(null,managerConst.MODE_SAVE)">등록
                </el-button>
            </div>
        </div>

        <div class="data" style="overflow-y: scroll;max-height: 750px">
            <el-table
                    :data="boardList"
                    style="width: 100%"
                    max-height="750"
                    border>

                <el-table-column
                        fixed
                        label="제목"
                        width="700">
                    <template slot-scope="scope">
                                <span style="padding: 2px 0;cursor: pointer;font-weight: bold"
                                      @click="editBoard(scope.row.id,managerConst.MODE_EDIT)">{{scope.row.title}}</span>
                        <i style="color: brown;padding: 0 10px" v-if="scope.row.commentQuantity > 0">({{scope.row.commentQuantity}})</i>
                        <i style="color: blue;padding: 0 10px" v-if="scope.row.betIds">[bet]</i>

                        <span style="color: #ff4d4d" v-if="scope.row.type==managerConst.Board.TYPE_FREE_BOARD && scope.row.alarmCheck != 1">[미확인]</span>
                        <div v-if="scope.row.titleimg">
                            <img :src="scope.row.titleimg" alt="이벤트이미지">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed
                        label="작성일짜"
                        width="130">
                    <template slot-scope="scope">
                        <span>{{scope.row.createTime|datef('yyyy-MM-DD HH:mm')}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                        fixed
                        label="작성자"
                        width="130">
                    <template slot-scope="scope">
                        <el-button size="mini"  v-if="scope.row.user" @click="editUser(scope.row.userId,managerConst.MODE_EDIT)">{{scope.row.user.nickname}}</el-button>
                        <span v-else>운영자</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="삭제"
                        width="200">
                    <template slot-scope="scope">
                        <el-button type="danger" size="mini" v-if="scope.row.status == managerConst.ENABLE"
                                   @click="updateDisplay(scope.row.id,managerConst.DISABLE)">삭제
                        </el-button>
                        <el-button type="danger" size="mini" v-if="scope.row.status == managerConst.DISABLE"
                                   @click="updateDisplay(scope.row.id,managerConst.ENABLE)">삭제복구
                        </el-button>
                        <el-button type="danger" size="mini" @click="realDelete(scope.row.id)">완전삭제</el-button>
                    </template>
                </el-table-column>


            </el-table>

        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 50, 150, 200]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

        <el-dialog title="수정/등록" :visible.sync="boardDialogVisible">

            <table class="table100">
                <tr>
                    <th colspan="6">제목</th>
                </tr>
                <tr>
                    <td colspan="6">
                        <el-input size="mini" v-model="boardDetail.title"></el-input>
                    </td>
                </tr>
                <tr v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE">
                    <th style="width: 16.6%">닉네임</th>
                    <th style="width: 16.6%">제목색상</th>
                    <th style="width: 16.6%">정열순위</th>
                    <th style="width: 16.6%">조회</th>
                    <th style="width: 16.6%">일자</th>
                    <th style="width: 16.6%">댓글입력</th>
                </tr>
                <tr v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE">
                    <td style="width: 16.6%">
                        <el-input size="mini" v-if="boardDetail&&boardDetail.user"
                                  v-model="boardDetail.user.nickname"></el-input>
                    </td>
                    <td style="width: 16.6%">
                        <el-color-picker v-model="boardDetail.titleColor" size="small"></el-color-picker>
                    </td>
                    <td style="width: 16.6%">
                        <el-input size="mini" v-if="boardDetail" v-model="boardDetail.sort"></el-input>
                    </td>
                    <td style="width: 16.6%">
                        <el-input size="mini" v-if="boardDetail" v-model="boardDetail.clicks"></el-input>
                    </td>
                    <td style="width: 16.6%">
                        <el-date-picker
                                v-model="boardDetail.noticeDate"
                                type="date"
                                size="mini"
                                placeholder="날짜선택">
                        </el-date-picker>
                    </td>
                    <td style="width: 16.6%">
                        <el-select size="mini" v-model="boardDetail.commentable">
                            <el-option :value="managerConst.ENABLE" label="가능">가능</el-option>
                            <el-option :value="managerConst.DISABLE" label="금지">금지</el-option>
                        </el-select>
                    </td>
                </tr>
                <tr  v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE">
                    <th colspan="3">제목 이미지</th>
                    <th colspan="3">이미지 등록</th>
                </tr>
                <tr v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE">
                    <td colspan="3">
                        <div class="" v-if="boardDetail">
                            <el-image
                                    style="width: 100px; height: 100px"
                                    :src="boardDetail.titleimgTemp"
                                    :preview-src-list="previewsrcList2">
                            </el-image>
                            <p>제목이미지 클릭 원본을 확인할수 있습니다</p>
                        </div>
                    </td>
                    <td colspan="3">
                        <el-upload
                                ref="upload"
                                class="upload-demo"
                                action="#"
                                :http-request="httpRequest"
                                :before-upload="beforeUpload"
                                :on-change="fileChanged"
                                :file-list="fileList"
                                :show-file-list="false">
                            <el-button slot="trigger" size="small" type="primary">제목 이미지선택
                            </el-button>
                            <el-button
                                    style="margin-left: 10px"
                                    size="small"
                                    type="success"
                                    @click="submitUpload(2)"
                            >제목 이미지등록
                            </el-button>
                        </el-upload>
                    </td>
                </tr>


                <tr  v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE">
                    <th colspan="3">내용 이미지</th>
                    <th colspan="3">내용 이미지 등록</th>
                </tr>
                <tr v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE">
                    <td colspan="3">
                        <div class="" v-if="boardDetail">
                            <el-image
                                    style="width: 100px; height: 100px"
                                    :src="boardDetail.eventImgTemp"
                                    :preview-src-list="previewsrcList">
                            </el-image>
                            <p>내용 이미지 클릭 원본을 확인할수 있습니다</p>
                        </div>
                    </td>
                    <td colspan="3">
                        <el-upload
                                ref="upload"
                                class="upload-demo"
                                action="#"
                                :http-request="httpRequest"
                                :before-upload="beforeUpload"
                                :on-change="fileChanged"
                                :file-list="fileList"
                                :show-file-list="false">
                            <el-button slot="trigger" size="small" type="primary">내용이미지선택
                            </el-button>
                            <el-button
                                    style="margin-left: 10px"
                                    size="small"
                                    type="success"
                                    @click="submitUpload(1)"
                            >내용이미지등록
                            </el-button>
                        </el-upload>
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="text-align: left">
                        <el-button type="primary" @click="save">수정하기</el-button>
                    </td>
                </tr>
                <tr>
                    <th colspan="6">내용</th>
                </tr>
                <tr>
                    <td colspan="6">
                        <div class="editor" style="padding: 5px">
                            <quill-editor ref="myQuillEditor"
                                          v-if="boardDetail.noticeType != managerConst.Board.NOTICE_TYPE_LINE"
                                          v-model="boardDetail.content"
                                          :options="editorOption"
                                          @ready="onEditorReady($event)"
                                          @change="onEditorChange($event)"/>

                            <el-input type="text" size="mini"
                                      v-if="boardDetail.noticeType == managerConst.Board.NOTICE_TYPE_LINE"
                                      v-model="boardDetail.content"></el-input>
                        </div>
                    </td>
                </tr>
            </table>

            <div style="width: 100%;max-height: 350px;overflow-y: scroll"
                 v-if="boardDetail&&boardDetail.sportsBetInfoList">
                <sports-bet-info-comp :list="boardDetail.sportsBetInfoList"></sports-bet-info-comp>
            </div>
            <div style="width: 100%;max-height: 350px;overflow-y: scroll"
                 v-if="boardDetail&&boardDetail.leisureGameBetInfoList">
                <leisure-game-bet-comp :list="boardDetail.leisureGameBetInfoList"></leisure-game-bet-comp>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="boardDialogVisible = false">취소</el-button>
                <el-button type="primary" @click="save">확인</el-button>
            </div>
        </el-dialog>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {updateGroup} from "../../network/manager/groupRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {deleteBoard, getBoardById, getBoardList, saveBoard, updateBoard} from "../../network/manager/boardRequest";
    import managerConst from "../../common/administrator/managerConst";
    import SportsBetInfoComp from "../../components/administrator/SportsBetInfoComp";
    import LeisureGameBetComp from "../../components/administrator/LeisureGameBetComp";
    import {Loading} from "element-ui";
    import {uploadFile} from "../../network/manager/commonRequest";

    Vue.prototype.$userEditToast = UserEditToast
    import * as Quill from 'quill'
    let fontSizeStyle = Quill.import('attributors/style/size')
    fontSizeStyle.whitelist = ['12px', '14px', '16px', '20px', '24px', '36px']
    Quill.register(fontSizeStyle, true)
    export default {
        name: "ManagerBoard",
        mixins: [manager],
        components: {LeisureGameBetComp, SportsBetInfoComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
        data() {
            return {
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                boardList: [],
                board: {type: 0, noticeType: 0},
                title: '',
                boardDetail: {eventImgTemp: '', eventImg: '',titleimgTemp: '', titleimg: '', content: ''},
                boardDialogVisible: false,
                previewsrcList: [],
                previewsrcList2: [],
                actionUrl: '/upload_image_3',
                fileList: [],
                file: null,
                mode: managerConst.MODE_EDIT,
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
                                ['blockquote', 'code-block'],         //引用，代码块
                                [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
                                [{'list': 'ordered'}, {'list': 'bullet'}],          //列表
                                [{'script': 'sub'}, {'script': 'super'}],      // 上下标
                                [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
                                [{'direction': 'rtl'}],                         // 文本方向
                                [{'size': ['12px', '14px', '16px', '20px', '24px', '36px'] }],  // 字体大小
                                [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题
                                [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
                                [{'font': []}],         //字体
                                [{'align': []}],        //对齐方式
                                ['clean'],        //清除字体样式
                            ]
                        }
                    },

                },
            }
        },
        methods: {

            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            editBoard(id, mode) {
                this.mode = mode
                this.boardDialogVisible = true
                if (mode == managerConst.MODE_EDIT) {
                    this.getBoardById(id)
                } else {
                    this.boardDetail = {eventImgTemp: '', eventImg: '', content: ''}
                    this.boardDetail.type = this.board.type
                    this.boardDetail.noticeType = this.board.noticeType
                }
            },
            getBoardById(id) {
                getBoardById(id).then(res => {
                    this.boardDetail = res.data.data
                    if (this.boardDetail.eventImg) {
                        this.previewsrcList.push(this.boardDetail.eventImg)
                        this.boardDetail.eventImgTemp = this.boardDetail.eventImg;
                        this.boardDetail.titleimg = null

                    }
                    if (this.boardDetail.titleimg) {
                        console.log(titleimg)
                        this.previewsrcList2.push(this.boardDetail.titleimg)
                        this.boardDetail.titleimgTemp = this.boardDetail.titleimg;
                        this.boardDetail.titleimg = null

                    }
                    this.getBoardList();
                })
            },
            getBoardList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getBoardList(this.board, this.pageNum, this.pageSize).then(res => {
                    this.boardList = res.data.data
                    this.pageTotal = res.data.total
                    loadingInstance.close()
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.get();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.get();
            },
            submitUpload(uploadtype) {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                uploadFile(this.actionUrl, this.file).then(res => {
                    this.getBoardList()

                    if (res.data.success) {
                        let uploadedFileName = res.data.data.uploadedFileName;
                        let fullpath = res.data.data.fullpath;
                        if(uploadtype == 1){
                            this.boardDetail.eventImg = uploadedFileName
                        }
                        if(uploadtype == 2){
                            this.boardDetail.titleimg = uploadedFileName
                        }
                        if (this.mode == managerConst.MODE_EDIT) {
                            updateBoard(this.boardDetail).then(res => {
                                this.getBoardById(this.boardDetail.id)
                            })
                        }
                    }
                    loadingInstance.close()
                    this.$message({
                        type: 'success',
                        duration: 3000,
                        message: '이미지 등록이  완료되였습니다. [확인]버튼으로 업데이트를 진행하세요'
                    });
                })
            },
            save() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if (this.mode == managerConst.MODE_EDIT) {
                    updateBoard(this.boardDetail).then(res => {
                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '업데이트 완료되였습니다'
                            });
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                        loadingInstance.close()
                        this.boardDialogVisible = false;
                        this.file = null
                        this.getBoardList()
                    })
                } else {
                    saveBoard(this.boardDetail).then(res => {
                        loadingInstance.close()
                        this.boardDialogVisible = false;
                        this.file = null
                        this.getBoardList()
                    })
                }
            },
            updateDisplay(id, status) {
                let record = {}
                record.id = id
                record.status = status
                updateBoard(record).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                        this.getBoardList()
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })

            },
            realDelete(id){
                this.$confirm('완전삭제 하시겠습니까?삭제후 복구 불가합니다', '완전삭제', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    deleteBoard(id).then(res=>{

                        if (res.data.success) {
                            this.$message({
                                type: 'success',
                                duration: 1000,
                                message: '삭제 완료되였습니다'
                            });
                            this.getBoardList()
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }

                    })

                });
            },
            httpRequest() {
                this.imgChange = true;
            },
            fileChanged(file, fileList) {
                this.file = file.raw;
            },
            beforeUpload(file) {

            },
            onEditorChange(editor) {
                this.boardDetail.content = editor.html;
            },
            onEditorReady(Quill) {

            },
            setMessageContentByTemplate(temp) {
                this.contentReplay = temp.reply
            },
            setPath(){
                let type = this.$route.query.type;
                let noticeType = this.$route.query.noticeType;
                this.board.type = type;
                this.board.noticeType = noticeType;
                this.getBoardList()
                if (type == managerConst.Board.TYPE_FREE_BOARD) {
                    this.title = '게시판'
                }
                if (type == managerConst.Board.TYPE_EVENT) {
                    this.title = '이벤트'
                }
                if (type == managerConst.Board.TYPE_NOTICE) {
                    if (noticeType == managerConst.Board.NOTICE_TYPE_IN_FREEBOARD) {
                        this.title = '공지사항'
                    }
                    if (noticeType == managerConst.Board.NOTICE_TYPE_IN_BETFORM) {
                        this.title = '베팅규칙'
                    }
                    if (noticeType == managerConst.Board.NOTICE_TYPE_LINE) {
                        this.title = '한줄공지'
                    }
                }
            }
        },
        created() {
            this.setPath()
            this.getBoardList()
        },
        watch: {
            $route: function (to, from) {
                this.setPath()
                this.getBoardList()
            },
        }
    }
</script>

<style scoped>

</style>